import React from "react"
import Layout from '../layouts/layout'


const Home = () => {
return (
  <Layout>

  <section className="support">
    <div className="support__header">

      {/* Site Title */}
      <h1 className="support__title">
        <span className="support__title-main">Support </span>
        <span className="support__title-sub">Page</span>
      </h1>

      <div className="support__body">
        <div className="support__body-social">
          <h3>Send us a DM on social media:</h3>
          
          <a href="https://instagram.com/paperballstudio">Instagram</a>
            
          <a href="https://twitter.com/paperballstudio">Twitter</a>

        </div>

        <h3 className="support__title">Contact</h3>
        <div className="support__form-container">
          <form className="support__form" name="contact-v1" data-netlify="true" method="post" netlify-honeypot="bot-field">

            {/* Hidden Inputs */}
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="support-v1"/>

          <label htmlFor="name" className="support__form-label">Your Name:</label>
          <input type="text" id="name" name="name" className="support__form-field" required/>

          <label htmlFor="email" className="support__form-label">Email:</label>
          <input type="email" id="email" name="email" className="support__form-field" required/>

          <label htmlFor="name" className="support__form-label"> Game:</label>
          <input type="text" id="name" name="name" className="support__form-field" required/>

          <label htmlFor="message" className="support__form-label">Message:</label>
          <textarea type="text" id="message" name="message" className="support__form-field" required/>

          <input className="support__form-button" id="submit" type="submit" value="Submit" />

          </form>
        </div>
          
      </div>
    </div>
  </section>

  </Layout>
)
}

export default Home